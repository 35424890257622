import './App.css';
import ConsultarPolizas from './views/pages/ConsultarPolizas';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const customTheme = createTheme({
  palette: {
    primary: {
      light: '#1a1536',
      main: '#1a1536',
      dark: '#0b061f',
      contrastText: '#fff'
    }
  }
})

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={customTheme}>
        <ConsultarPolizas/>
      </ThemeProvider>
    </div>
  );
}

export default App;
