import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import DescriptionIcon from "@mui/icons-material/Description";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import { CircularProgress, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import download from "js-file-download";
import logo from '../../resources/images/logo-rino.jpeg'
import usa from '../../resources/images/united-states-of-america-flag-png-large.png'
import mexico from '../../resources/images/mexico-flag-png-large.png'

const ConsultarPolizas = (props) => {
  const [numPoliza, setNumPoliza] = useState("");
  const [asegurado, setAsegurado] = useState("");
  //const [error, setError] = useState('')
  const [loading, setLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(null);
  const [imageUri, setImageUri] = useState("");
  const [persona, setPersona] = useState("fisica");

  const handleConsultar = async () => {
    const payload = {
      numPoliza,
      asegurado,
      persona
    };
    setLoading(true);
    axios
      .post("https://serviciosrinorisk.com/poliza/consultar-poliza", payload)
      .then((res) => {
        setResponseStatus(res.data.status);
        setImageUri(res.data.data[0].uri);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleDescargar = () => {
    setLoading(true);
    console.log(imageUri)
    axios
      .get("https://serviciosrinorisk.com" + imageUri, {
        responseType: "blob",
      })
      .then((res) => {
        download(res.data, numPoliza + ".pdf")
          .then(() => {
            setResponseStatus(null);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            setResponseStatus(null);
          });
        limpiarForm();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setResponseStatus(null);
      });
  };

  const limpiarForm = () => {
    setAsegurado("");
    setNumPoliza("");
  };

  return (
    <>
      <Box id='header' fullWidth bgcolor={'primary.dark'} display={'flex'} justifyContent={'space-between'} px={2} pt={1}>
        <img src={logo} width={'100px'}  />
        <Box sx={{ display: "flex", justifyContent: "center", gap: "10px", alignSelf: "center"}}>
          <img src={mexico} width={'60px'} height={'40px'} />
          <img src={usa} width={'60px'}  height={'40px'} />
        </Box>
      </Box>
    <Container component="main" maxWidth="sm">
      <Box sx={{ mt: 10 }} onSubmit={() => {}}>
        <Typography component="h1" variant="h3">
          Consultar poliza
        </Typography>
        <Box fullWidth sx={{ display: "flex", justifyContent: "center" }}>
          <Avatar sx={{ bgcolor: "primary.main" }}>
            <DescriptionIcon />
          </Avatar>
        </Box>
        <Box pt={1}><b>Portal para descargar tu poliza de responsabilidad civil en ESTADOS UNIDOS.</b></Box>


        {loading ? (
          <Box my={12}>
            <h2>Cargando...</h2>
            <CircularProgress size={28} />
          </Box>
        ) : !loading && responseStatus == null ? (
          <Box sx={{ mt: 5 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="numpoliza"
              label="Num. de Poliza"
              name="numpoliza"
              autoComplete="numpoliza"
              value={numPoliza}
              onChange={(event) => {
                setNumPoliza(event.target.value);
              }}
              autoFocus
            />
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="fisica"
              value={persona}
              onChange={(event) => {
                setPersona(event.target.value)
              }}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="fisica"
                control={<Radio />}
                label="Persona fisica"
              />
              <FormControlLabel
                value="moral"
                control={<Radio />}
                label="Persona moral"
              />
            </RadioGroup>
            <TextField
              margin="normal"
              required
              fullWidth
              id="asegurado"
              label={persona === 'fisica' ? "Asegurado (Nombre, primer apellido o ambos)" : 'Razon social'}
              name="asegurado"
              autoComplete="asegurado"
              value={asegurado}
              onChange={(event) => {
                setAsegurado(event.target.value.toUpperCase());
              }}
              autoFocus
            />
            <Button
              onClick={handleConsultar}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              <SearchIcon sx={{ mr: 1 }} />
              Consultar
            </Button>
          </Box>
        ) : !loading && responseStatus ? (
          <Box>
            <h3>Poliza encontrada</h3>
            <Button
              onClick={handleDescargar}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              <DownloadIcon sx={{ mr: 1 }} />
              Descargar la poliza
            </Button>
          </Box>
        ) : !loading && !responseStatus ? (
          <Box>
            <h3>El numero de poliza no existe en la base de datos.</h3>
            <Button
              onClick={() => {
                setResponseStatus(null);
              }}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              <RefreshIcon sx={{ mr: 1 }} />
              Reintentar
            </Button>
          </Box>
        ) : (
          false
        )}
      </Box>
    </Container>
</>
  );
};

export default ConsultarPolizas;
